import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function GiftIcon(props: SvgIconProps) {
	return (
		<SvgIcon {...props} viewBox='0 0 800 800'>
			<path d='M314.6,155.9l45.4,77.2h-1.7h-93.9c-28.8,0-52.2-23.3-52.2-52.2s23.3-52.2,52.2-52.2h2.9 C286.7,128.8,304.8,139.1,314.6,155.9z M149.6,180.9c0,18.8,4.6,36.5,12.5,52.2h-54.2c-23.1,0-41.7,18.6-41.7,41.7v83.5 c0,23.1,18.6,41.7,41.7,41.7h584.2c23.1,0,41.7-18.6,41.7-41.7v-83.5c0-23.1-18.6-41.7-41.7-41.7h-54.2c8-15.6,12.5-33.4,12.5-52.2 c0-63.4-51.4-114.7-114.7-114.7h-2.9c-41.6,0-80.2,22-101.3,57.9L400,177.7l-31.4-53.5c-21.1-36-59.7-58-101.3-58h-2.9 C201,66.2,149.6,117.6,149.6,180.9z M587.8,180.9c0,28.8-23.3,52.2-52.2,52.2h-93.9H440l45.4-77.2c9.9-16.8,27.9-27.1,47.3-27.1h2.9 C564.4,128.8,587.8,152.1,587.8,180.9z M107.9,441.7v229.5c0,34.6,28,62.6,62.6,62.6h187.8V441.7H107.9z M441.7,733.8h187.8 c34.6,0,62.6-28,62.6-62.6V441.7H441.7V733.8z' />
		</SvgIcon>
	);
}

export function GoogleIcon(props: SvgIconProps) {
	return (
		<SvgIcon {...props} viewBox='0 0 256 262'>
			<path
				fill='#4285F4'
				d='M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027'
			/>
			<path
				fill='#34A853'
				d='M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1'
			/>
			<path
				fill='#FBBC05'
				d='M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782'
			/>
			<path
				fill='#EB4335'
				d='M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251'
			/>
		</SvgIcon>
	);
}

export function FacebookIcon(props: SvgIconProps) {
	return (
		<SvgIcon {...props} viewBox='0 0 14222 14222'>
			<path
				fill='#1977F3'
				d='M14222 7111c0,-3927 -3184,-7111 -7111,-7111 -3927,0 -7111,3184 -7111,7111 0,3549 2600,6491 6000,7025l0 -4969 -1806 0 0 -2056 1806 0 0 -1567c0,-1782 1062,-2767 2686,-2767 778,0 1592,139 1592,139l0 1750 -897 0c-883,0 -1159,548 -1159,1111l0 1334 1972 0 -315 2056 -1657 0 0 4969c3400,-533 6000,-3475 6000,-7025z'
			/>
			<path
				fill='#FEFEFE'
				d='M9879 9167l315 -2056 -1972 0 0 -1334c0,-562 275,-1111 1159,-1111l897 0 0 -1750c0,0 -814,-139 -1592,-139 -1624,0 -2686,984 -2686,2767l0 1567 -1806 0 0 2056 1806 0 0 4969c362,57 733,86 1111,86 378,0 749,-30 1111,-86l0 -4969 1657 0z'
			/>
		</SvgIcon>
	);
}
